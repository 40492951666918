@use '../common/constants';
@use '../common/default-theme';
@use '../common/colors';

.google-order-list-item-widget {
  border-radius: constants.$border-radius;
  background: #{default-theme.$modifier-gradient-bg};
  box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
  padding: 13px 20px;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  height: fit-content;

  .order-data {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 20% 15% 10% 20% 10% 20%;
    align-items: center;
    gap: 1%;
  }

  .orders-list-item-customer-info {
    display: grid;
    grid-template-areas:
      'order-logo   customer-name'
      'order-logo   customer-phone-number';
    grid-template-columns: min-content 1fr;
    gap: 0 10px;
    min-width: 0;

    .order-logo {
      grid-area: order-logo;
      display: grid;

      .google-logo {
        width: 28px !important;
        align-self: center;
      }

      > .imgix {
        display: grid;
        align-self: center;

        > img {
          width: 28px !important;
          border-radius: 50%;
        }
      }
    }

    .customer-name {
      grid-area: customer-name;
      color: #{default-theme.$fg};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .customer-phone-number {
      grid-area: customer-phone-number;
      font-size: 14px;
      color: #{default-theme.$tertiary};
    }
  }

  .orders-list-item-collection-info {
    .requested-for {
      color: #{colors.$rose-600};
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .orders-list-item-grand-total,
  .orders-list-item-docket-number {
    text-align: left;
    margin: 0;
    font-size: 18px;
  }

  .orders-list-item-order-status {
    font-weight: 600;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 30px;

    &.to-be-confirmed {
      color: #{colors.$yellow-600};
      background-color: #{colors.$yellow-100};
    }

    &.accepted,
    &.in-preparation,
    &.ready-for-pickup,
    &.in-transit,
    &.completed {
      color: #{colors.$green-800};
      background-color: #{colors.$green-100};
    }

    &.cancelled,
    &.rejected {
      color: #{colors.$rose-800};
      background-color: #{colors.$rose-100};
    }
  }

  .actions {
    display: flex;
    gap: 20px;
    cursor: pointer;
  }

  .orders-list-item-print-order-button {
    justify-self: end;
    opacity: 0.8;
    padding: 0px 10px;
  }
  .edit-icon {
    margin-left: auto;
  }
}
