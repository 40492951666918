@use '../common/colors';
@use '../common/typography';
@use '../common/elements';
@use '../common/ghost';
@use '../common/pill';
@use '../common/order-history-list';

@mixin container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: order-history-list.$grid-template-columns;
  align-items: center;
  gap: 12px;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  color: colors.$blue-gray-900;

  cursor: pointer;
}

@mixin content {
  .orders-list-item-customer-info {
    display: grid;
    grid-template-areas:
      'order-logo   customer-name'
      'order-logo   customer-phone-number';
    grid-template-columns: min-content 1fr;
    gap: 0 10px;
    min-width: 0;

    .order-logo {
      grid-area: order-logo;
      display: grid;

      .google-logo,
      .uber-eats-logo {
        width: 28px !important;
        align-self: center;
      }

      > .imgix {
        display: grid;
        align-self: center;

        > img {
          width: 28px !important;
          border-radius: 50%;
        }
      }
    }

    .customer-name {
      grid-area: customer-name;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-900;
    }

    .customer-phone-number {
      grid-area: customer-phone-number;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-500;
    }
  }

  .orders-list-item-collection-info {
    max-width: 100%;
    overflow: hidden;

    .collection-type {
      display: flex;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-900;

      > span {
        margin-left: 3px;
        margin-top: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: colors.$blue-gray-500;
      }
    }

    .pickup-in-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: colors.$blue-800;
    }

    .requested-for {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-500;
    }

    .table-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-500;
      display: flex;
      align-items: center;
      max-width: 100%;

      img {
        margin-right: 4px;
      }

      .table-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .orders-list-item-grand-total {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: colors.$blue-gray-900;
  }

  .orders-list-item-order-status {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .orders-list-item-payment-status {
    display: grid;
    grid-template-columns: min-content min-content 1fr;
    align-items: center;

    > .payment-status {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      &.paid {
        @include pill.primary-green;
      }
      &.not-paid {
        @include pill.primary-red;
      }
      &.refunded {
        @include pill.primary-yellow;
      }
    }

    > .bullet {
      margin: 0 4px;
    }

    .payment-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: colors.$blue-gray-900;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .orders-list-item-docket-number {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: colors.$blue-gray-900;
  }

  .orders-list-item-placed-date-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: colors.$blue-gray-900;
  }

  .orders-list-item-print-order-button {
    @include elements.icon(22px);
  }
}
