@use "../common/constants";
@use "../common/colors";
@use "../common/typography";

.incoming-online-orders-list-item-widget {
  border-radius: constants.$border-radius;
  background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
  box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;

  .order-data {
    padding: 13px 25px 13px 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr 1fr min-content;
    cursor: pointer;
    gap: 1%;
    align-items: center;

    .incoming-orders-list-item-logo {
      display: grid;

      .uber-eats-logo {
        align-self: center;
      }

      > .imgix {
        display: grid;
        align-self: center;

        > img {
          width: 40px !important;
          border-radius: 50%;
        }
      }
    }

    .incoming-orders-list-item-customer-info {
      .customer-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: colors.$blue-gray-900;
      }

      .customer-phone-number,
      .customer-delivery-address {
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-700;
      }
    }

    .incoming-orders-list-item-collection-info {
      .collection-type {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: colors.$blue-gray-900;

        > span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: colors.$blue-gray-500;
        }
      }

      .estimated-pickup-time {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-800;
      }

      .requested-for {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-700;
      }

      .time-since-label-widget,
      .requested-for-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-500;
      }
    }

    .orders-list-item-grand-total {
      font-size: 18px;
    }
  }

  .incoming-orders-list-item-store-name {
    background-color: colors.$white;
    border: 1px solid colors.$blue-gray-100;
    padding: 4px 12px;
    border-radius: constants.$border-radius-small;
    width: fit-content;
    margin-bottom: 3px;

    .store-info {
      display: inline;
      padding-left: 8px;
      color: colors.$blue-gray-700;
      @include typography.caption-1-400;
    }
  }
}
